<template>
  <v-card max-width="600" class="mx-auto py-1" :loading="loading">
    <v-treeview
      activatable
      :active.sync="active"
      hoverable
      :items="searchItems"
      return-object
      ref="treeview"
    >
      <template v-slot:prepend="{ open, item }">
        <DocumentItemIcon :value="item" :open="open" />
      </template>
      <template v-slot:label="{ item }">
        <DocumentItem :value="item" />
      </template>
      <template v-slot:append="{ item, open }">
        <DocumentItemAppend :value="item" :open="open" />
      </template>
    </v-treeview>
    <router-view></router-view>
  </v-card>
</template>

<script>
import { defineComponent } from "vue";
import DocumentItem from "./Components/DocumentItem.vue";
import DocumentItemIcon from "./Components/DocumentItemIcon.vue";
import DocumentItemAppend from "./Components/DocumentItemAppend.vue";
import { formatDate } from "common/utils/date";
import { debounce } from "lodash";
export default defineComponent({
  components: { DocumentItem, DocumentItemIcon, DocumentItemAppend },
  props: ["search"],
  data() {
    return {
      categories: [],
      searchItems: [],
      active: [],
      documents: [],
      loading: false,
    };
  },
  computed: {
    filter() {
      return (item) => item.documentsCount > 0;
    },
  },
  watch: {
    active(arr) {
      if (arr.length) {
        const item = arr[0];
        if (!item.children) {
          this.$router.push(
            `/documents/search/${this.search}/document/${item.id}`
          );
        }
        this.active = [];
      }
    },
    async search() {
      if (this.search.length >= 3) {
        this.getSearch();
      }
    },
  },
  methods: {
    getSearch: debounce(async function () {
      this.loading = true;
      this.searchItems = [];
      const results = await this.apiList({
        resource: "document/category",
        query: `search=${this.search}`,
      });
      this.searchItems = results;
      this.loading = false;
      await this.$nextTick();
      this.$refs.treeview.updateAll(true);
    }, 500),
    formatDate,
  },
  async created() {
    if (this.search.length >= 3) {
      this.getSearch();
    }
  },
});
</script>
